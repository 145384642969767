/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react"
import { graphql, Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import useScript from "../hooks/use-script"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BlogTemplate = ({ data }) => {
  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-sidebar.min.js"))

  useScript(withPrefix("js/sticky-sidebar-social.js"))

  const [pageUrl, setPageUrl] = useState("")

  useEffect(() => {
    if (typeof window !== "undefined") {
      setPageUrl(encodeURIComponent(window.document.URL))
    }
  }, [])

  const {
    metaTitle,
    metaDescription,
    metaOgImageType,
    metaOgImage,
    metaOgImageAlt,
    metaOgImageWidth,
    metaOgImageHeight,
    title,
    cover,
    content,
    category,
    slug,
    author,
    published_at,
  } = data.strapiPosts

  const TOTAL_WORDS = title?.split(" ").length + content.split(" ").length

  const TIME_TO_READ_IN_MINUTES = Math.ceil(TOTAL_WORDS / 200)

  const meta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: `https://documentesign.com/blog/${slug}/`,
    },
    {
      property: `og:site_name`,
      content: `Document eSign`,
    },
    {
      property: `og:type`,
      content: `post`,
    },
    {
      property: `og:image`,
      content: `https://cms-admin.documentesign.com${metaOgImage}`,
    },
    {
      property: `og:image:secure_url`,
      content: `https://cms-admin.documentesign.com${metaOgImage}`,
    },
    {
      property: `og:image:alt`,
      content: metaOgImageAlt || `Blog`,
    },
    {
      property: `og:image:type`,
      content: metaOgImageType,
    },
    {
      property: `og:image:width`,
      content: metaOgImageWidth || `1200`,
    },
    {
      property: `og:image:height`,
      content: metaOgImageHeight || `745`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: `https://cms-admin.documentesign.com${metaOgImage}`,
    },
    {
      name: "google-site-verification",
      content: "8T35XrGQksJPj8EAGes0nCLcUNsAaZGzjnF6TwlnDj4",
    },
  ]

  const schema = {
    "@context": "https://schema.org",
    "@type": "article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://documentesign.com/" + slug + "/#webpage",
      url: "https://documentesign.com/" + slug + "/",
    },
    headline: title,
    description: metaDescription,
    image: {
      "@type": "ImageObject",
      url: "https://cms-admin.documentesign.com/" + metaOgImage,
      width: 700,
      height: 400,
    },
    author: {
      "@type": "person",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: "Document eSign",
      logo: {
        "@type": "ImageObject",
        url:
          "https://documentesign.com/static/ca8aed1a72c0bfcca722e15da72ada1e/document-eSign-logo.svg",
      },
    },
    datePublished: published_at,
    dateModified: published_at,
  }

  return (
    <Layout rawData={{ className: "sticky-header" }}>
      <SEO
        title={metaTitle || "Document eSign - Blog"}
        description={metaDescription || "Document eSign - Blog"}
        meta={meta}
        schemaMarkup={schema}
      />
      <div className="intro blog-details-intro show-on-scroll ani-in">
        <div className="container pt-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0 bg-white">
              <li className="breadcrumb-item">
                <Link to="/">
                  <small>Home</small>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/blog/">
                  <small>Blog</small>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <small className="pt-1 d-block-inline text-truncate breadcrumb-item-truncate">
                  {title}
                </small>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container pb-3 pt-0">
        <div className="row">
          <div className="col-lg-12 show-on-scroll ani-in">
            <h1 className="blog-content-title">{title}</h1>
            <div className="d-md-flex align-items-md-center">
              <div className="flex-grow-1">
                <small className="text-muted">
                  Posted on {published_at} in<a href="#"> {category?.name} </a>
                  by {author}
                </small>
                <span className="px-1">•</span>
                <small className="text-muted">
                  {TIME_TO_READ_IN_MINUTES} min read
                </small>
              </div>
              <div className="d-flex align-items-center mt-3 mt-sm-0">
                <a
                  href={"https://www.facebook.com/sharer.php?u=" + pageUrl}
                  className="btn-share btn-fb ml-md-1"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href={`https://twitter.com/share?text=${title}&url=${pageUrl}`}
                  className="btn-share btn-tw ml-md-1"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href={
                    "https://www.linkedin.com/shareArticle?mini=true&url=" +
                    pageUrl
                  }
                  className="btn-share btn-in ml-md-1"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  href={
                    "https://pinterest.com/pin/create/button/?url=" + pageUrl
                  }
                  className="btn-share btn-pin ml-md-1"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  <i className="fab fa-pinterest-p"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="my-4 px-lg-3 show-on-scroll ani-in">
            <img
              data-src={cover?.publicURL}
              className="img-fluid lazyload"
              alt={metaOgImageAlt}
            />
          </div>
        </div>
      </div>

      <div className="sticky-sidebar-container w-100 px-lg-5">
        <div className="container mb-5 pb-lg-5">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 sticky-sidebar-container">
              <div className="blog-content show-on-scroll ani-in">
                <ReactMarkdown
                  source={content}
                  allowDangerousHtml
                ></ReactMarkdown>
              </div>
              <div className="card border-0 shadow-sm mt-5 subscribe-newsletter show-on-scroll ani-in">
                <div className="card-body p-lg-5">
                  <div className="row">
                    <div className="col-lg-8">
                      <h3>Subscribe Us</h3>
                      <p className="mt-3 mb-4">
                        Get Document eSign latest articles straight to your
                        inbox.
                      </p>
                      <div className="d-md-flex align-items-md-center newsletter-from">
                        <div className="flex-md-grow-1">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                        <div className="flex-md-shrink-0 mt-3 mt-md-0">
                          <button
                            className="btn btn-dark btn-block"
                            type="button"
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 pl-lg-5 d-none d-lg-block">
                      <img
                        data-src={withPrefix("images/subscribe.svg")}
                        className="img-fluid lazyload"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="float-share" id="floatsharebx">
          <div className="sticky-sidebar-inner">
            <div id="sidebar-inner">
              <div>
                <small className="text-muted">SHARE</small>
              </div>
              <a
                href={"https://www.facebook.com/sharer.php?u=" + pageUrl}
                className="btn-share btn-fb"
                rel="nofollow noopener"
                target="_blank"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href={`https://twitter.com/share?text=${title}&url=${pageUrl}`}
                className="btn-share btn-tw"
                rel="nofollow noopener"
                target="_blank"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href={
                  "https://www.linkedin.com/shareArticle?mini=true&url=" +
                  pageUrl
                }
                className="btn-share btn-in"
                rel="nofollow noopener"
                target="_blank"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                href={"https://pinterest.com/pin/create/button/?url=" + pageUrl}
                className="btn-share btn-pin"
                rel="nofollow noopener"
                target="_blank"
              >
                <i className="fab fa-pinterest-p"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Similar articles html should be here */}
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query GetSingleBlog($slug: String) {
    strapiPosts(slug: { eq: $slug }) {
      metaTitle
      metaDescription
      metaOgImage
      metaOgImageType
      metaOgImageAlt
      metaOgImageHeight
      metaOgImageWidth
      title
      content
      author
      cover {
        publicURL
      }
      category {
        name
        slug
      }
      slug
      published_at(formatString: "MMM DD, yy")
    }
  }
`
